import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  widgetBuilder.set({
    displayName: flowAPI.translations.t(
      'app.settings.widgetDesignPanel.displayName',
    ),
    nickname: 'CountdownWidget',
  });

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: flowAPI.translations.t('app.settings.widgetDesignPanel.title'),
      presetsTitle: 'presets',
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: flowAPI.translations.t(
            'app.settings.widgetDesignPanel.background',
          ),
        })
        .groups()
        .set({
          roles: ['box1'],
        });
    });

    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: flowAPI.translations.t(
            'app.settings.widgetDesignPanel.title.text',
          ),
        })
        .groups()
        .set({
          roles: ['text1', 'text2'],
        });
    });
    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: flowAPI.translations.t(
            'app.settings.widgetDesignPanel.actionButton',
          ),
        })
        .groups()
        .set({
          roles: ['button1', 'button2'],
        });
    });
  });
};
