import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  widgetBuilder.gfpp().set('help', {
    id: 'c327f46d-251e-4381-ba59-118d6dd9173b',
  });
  widgetBuilder.set({
    displayName: flowAPI.translations.t(
      'app.settings.counterDesignPanel.displayName',
    ),
    nickname: 'Timer1Widget',
  });

  const textIds = ['labelDays', 'labelHours', 'labelMinutes', 'labelSeconds'];
  const unitTextIds = [
    'Days',
    'Hours',
    'Minutes',
    'Seconds',
    'text1',
    'text2',
    'text3',
    'text4',
  ];
  const containerIds = ['days', 'hours', 'minutes', 'seconds'];
  const labelsContainers = ['box18', 'box19', 'box20', 'box21'];
  const unitsContainers = [
    'DD',
    'box15',
    'box16',
    'box17',
    'box23',
    'box28',
    'box26',
    'box24',
  ];
  const repeaterContainers = [
    'repeater1',
    'repeater2',
    'repeater3',
    'repeater4',
    'box22',
    'box23',
    'box29',
    'box28',
    'box27',
    'box26',
    'box25',
    'box24',
  ];
  [
    ...unitsContainers,
    ...labelsContainers,
    ...containerIds,
    ...unitTextIds,
    ...textIds,
    ...repeaterContainers,
  ].forEach((id) => {
    widgetBuilder.configureConnectedComponents(id, (innerWidgetBuilder) => {
      innerWidgetBuilder.behavior().set({
        closed: {
          selectable: false,
        },
      });
    });
  });

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: flowAPI.translations.t(
        'app.settings.counterDesignPanel.actionButton',
      ),
      presetsTitle: flowAPI.translations.t(
        'app.settings.counterDesignPanel.choose.a.layout',
      ),
    });
    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: flowAPI.translations.t(
            'app.settings.counterDesignPanel.background',
          ),
        })
        .groups()
        .set({
          roles: [
            'timerContainer',
            ...textIds,
            ...unitTextIds,
            ...containerIds,
            ...labelsContainers,
            ...unitsContainers,
          ],
        });
      tabBuilder.addSection((sectionBuilder) => {
        sectionBuilder.set({
          state: 'active',
          category: 'fill',
          label: flowAPI.translations.t(
            'app.settings.counterDesignPanel.backgroundSection.clock.background',
          ),
          priority: 1,
          roles: ['timerContainer'],
          states: ['regular', 'hover'],
        });
      });
      tabBuilder.addSection((sectionBuilder) => {
        sectionBuilder.set({
          state: 'active',
          category: 'fill',
          label: flowAPI.translations.t(
            'app.settings.counterDesignPanel.backgroundSection.digits.background',
          ),
          priority: 2,
          roles: unitsContainers,
          states: ['regular', 'hover'],
        });
      });
      tabBuilder.addSection((sectionBuilder) => {
        sectionBuilder.set({
          state: 'active',
          category: 'fill',
          label: flowAPI.translations.t(
            'app.settings.counterDesignPanel.backgroundSection.labels.background',
          ),
          priority: 3,
          roles: labelsContainers,
          states: ['regular', 'hover'],
        });
      });
      tabBuilder.addSection((sectionBuilder) => {
        sectionBuilder.set({
          state: 'active',
          category: 'fill',
          label: flowAPI.translations.t(
            'app.settings.counterDesignPanel.backgroundSection.digitsAndLabels.background',
          ),
          priority: 4,
          roles: containerIds,
          states: ['regular', 'hover'],
        });
      });
      widgetDesignTabsBuilder.addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: flowAPI.translations.t(
              'app.settings.counterDesignPanel.time.units',
            ),
          })
          .groups()
          .set({
            roles: unitTextIds,
          });
      });
      widgetDesignTabsBuilder.addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: flowAPI.translations.t(
              'app.settings.counterDesignPanel.labels',
            ),
          })
          .groups()
          .set({
            roles: textIds,
          });
      });
      widgetDesignTabsBuilder.addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: flowAPI.translations.t(
              'app.settings.counterDesignPanel.separators',
            ),
          })
          .groups()
          .set({
            roles: ['seperator1', 'seperator2', 'seperator3'],
          });
      });
    });
    widgetBuilder.gfpp('mobile').set('mainAction1', {
      behavior: 'HIDE',
    });
  });
};
