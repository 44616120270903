import { TFunction } from '@wix/yoshi-flow-editor';
import { END_MESSAGE_STATE, COUNTDOWN_CLOCK_STATE } from '../constants';

const COMPS_DATA = {
  countdownTitle: {
    role: 'text1',
    label: 'app.settings.elementsPanel.categories.countdown.clock.title.text',
  },
  countdownActionButton: {
    role: 'button1',
    label:
      'app.settings.elementsPanel.categories.countdown.clock.action.button',
  },
  endMessageTitle: {
    role: 'text2',
    label: 'app.settings.elementsPanel.categories.end.message.title.text',
  },
  endMessageActionButton: {
    role: 'button2',
    label: 'app.settings.elementsPanel.categories.end.message.action.button',
  },
  timerUnitsDays: {
    role: 'days',
    label: 'app.settings.elementsPanel.categories.time.unit.days',
  },
  timerUnitsHours: {
    role: 'hours',
    label: 'app.settings.elementsPanel.categories.time.unit.hours',
  },
  timerUnitsMinutes: {
    role: 'minutes',
    label: 'app.settings.elementsPanel.categories.time.unit.minutes',
  },
  timerUnitsSeconds: {
    role: 'seconds',
    label: 'app.settings.elementsPanel.categories.time.unit.seconds',
  },
};

const CATEGORIES_IDS = {
  countdownInfo: 'app.settings.elementsPanel.categories.countdown.clock',
  endMessageInfo: 'app.settings.elementsPanel.categories.end.message',
  timerUnits: 'app.settings.elementsPanel.categories.time.unit',
};

export const categoriesData = (t: TFunction) => [
  {
    id: t(CATEGORIES_IDS.countdownInfo),
    title: t(CATEGORIES_IDS.countdownInfo),
    state: COUNTDOWN_CLOCK_STATE,
  },
  {
    id: t(CATEGORIES_IDS.endMessageInfo),
    title: t(CATEGORIES_IDS.endMessageInfo),
    state: END_MESSAGE_STATE,
  },
  {
    id: t(CATEGORIES_IDS.timerUnits),
    title: t(CATEGORIES_IDS.timerUnits),
    state: COUNTDOWN_CLOCK_STATE,
  },
];

export const elementsData = (t: TFunction) => [
  {
    label: t(COMPS_DATA.countdownTitle.label),
    identifier: { role: COMPS_DATA.countdownTitle.role },
    categoryId: t(CATEGORIES_IDS.countdownInfo),
    index: 0,
  },
  {
    label: t(COMPS_DATA.countdownActionButton.label),
    identifier: { role: COMPS_DATA.countdownActionButton.role },
    categoryId: t(CATEGORIES_IDS.countdownInfo),
    index: 1,
  },
  {
    label: t(COMPS_DATA.endMessageTitle.label),
    identifier: { role: COMPS_DATA.endMessageTitle.role },
    categoryId: t(CATEGORIES_IDS.endMessageInfo),
    index: 2,
  },
  {
    label: t(COMPS_DATA.endMessageActionButton.label),
    identifier: { role: COMPS_DATA.endMessageActionButton.role },
    categoryId: t(CATEGORIES_IDS.endMessageInfo),
    index: 3,
  },
  {
    label: t(COMPS_DATA.timerUnitsDays.label),
    identifier: { role: COMPS_DATA.timerUnitsDays.role },
    categoryId: t(CATEGORIES_IDS.timerUnits),
    index: 4,
  },
  {
    label: t(COMPS_DATA.timerUnitsHours.label),
    identifier: { role: COMPS_DATA.timerUnitsHours.role },
    categoryId: t(CATEGORIES_IDS.timerUnits),
    index: 5,
  },
  {
    label: t(COMPS_DATA.timerUnitsMinutes.label),
    identifier: { role: COMPS_DATA.timerUnitsMinutes.role },
    categoryId: t(CATEGORIES_IDS.timerUnits),
    index: 6,
  },
  {
    label: t(COMPS_DATA.timerUnitsSeconds.label),
    identifier: { role: COMPS_DATA.timerUnitsSeconds.role },
    categoryId: t(CATEGORIES_IDS.timerUnits),
    index: 7,
  },
];
