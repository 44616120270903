import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { categoriesData, elementsData } from './showHideData';
import { TFunction } from '@wix/yoshi-flow-editor';
import { END_MESSAGE_STATE } from '../constants';

export const openShowHidePanel = (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  t: TFunction,
  currentTab: any,
  allAncestorsRefs,
): Promise<void> => {
  const widgetRefAncestors: ComponentRef[] | null = allAncestorsRefs;
  const getCollapsedRefComponentByRole = async (role) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        'token',
        {
          componentRef: widgetRefHost,
          // @ts-expect-error temp until types are GAed
          includeInnerCollapsed: true,
        },
      );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );
    return collapsedRefComponent[0].componentRef;
  };

  const addSeparatorInTimerUnits = async (role) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponentsPromise =
      editorSDK.components.refComponents.getCollapsedRefComponents('token', {
        componentRef: widgetRefHost,
        // @ts-expect-error temp until types are GAed
        includeInnerCollapsed: true,
      });
    const unitsDataBeforeShowSeparatorPromise =
      getUnitsDataBeforeAddSeparator(role);
    const [collapsedRefComponents, unitsDataBeforeShowSeparator] =
      await Promise.all([
        collapsedRefComponentsPromise,
        unitsDataBeforeShowSeparatorPromise,
      ]);
    const notCollapsedItems = unitsDataBeforeShowSeparator.filter((data) => {
      return !data.isCollapsed;
    });
    for (const data of notCollapsedItems) {
      const index = notCollapsedItems.indexOf(data);
      if (index < notCollapsedItems.length - 1) {
        if (
          !data.isCollapsed &&
          data.isDigit &&
          !notCollapsedItems[index + 1].isCollapsed &&
          notCollapsedItems[index + 1].isDigit
        ) {
          const tempIndex = unitsDataBeforeShowSeparator.findIndex(
            (roleData) => {
              return roleData.role === data.role;
            },
          );
          const ref = collapsedRefComponents.find(
            (comp) =>
              comp.role === unitsDataBeforeShowSeparator[tempIndex + 1].role,
          );
          if (ref) {
            await showComp(ref.componentRef);
          }
        }
      }
    }
    const isSeparatorsAreCollapsed = await isSeparatorsCollapsed();
    if (widgetRefAncestors) {
      await editorSDK.application.appStudioWidgets.props.set('token', {
        widgetRef: widgetRefAncestors[0],
        newProps: {
          showSeparators: !isSeparatorsAreCollapsed,
        },
      });
    }
  };
  const getUnitsDataBeforeAddSeparator = async (role) => {
    const unitsRoles = [
      'days',
      'seperator1',
      'hours',
      'seperator2',
      'minutes',
      'seperator3',
      'seconds',
    ];

    const separatorsRole = ['seperator1', 'seperator2', 'seperator3'];
    if (unitsRoles.includes(role)) {
      let unitsDataBeforeShowSeparator: any = [];
      let isCollapsedComponentByRolePromises: Promise<any>[] = [];
      isCollapsedComponentByRolePromises = unitsRoles.map((unitRole) =>
        isCollapsedComponentByRole(unitRole),
      );
      const values = await Promise.all(isCollapsedComponentByRolePromises);
      unitsDataBeforeShowSeparator = unitsRoles.map((unitRole, index) => ({
        role: unitRole,
        isCollapsed: values[index],
        isDigit: !separatorsRole.includes(unitRole),
      }));
      return unitsDataBeforeShowSeparator;
    }
  };
  const isCollapsedComponentByRole = async (role: string) => {
    const promises: any = widgetRefAncestors?.map(async (currentRef) => {
      const elementRef = await editorSDK.document.components.findAllByRole(
        'token',
        {
          controllerRef: currentRef,
          role,
        },
      );
      if (!elementRef.length) {
        return false;
      }
      const isCollapsed =
        await editorSDK.document.components.refComponents.isRefComponentCollapsed(
          'token',
          { componentRef: elementRef[0] },
        );
      return isCollapsed;
    });
    const all = await Promise.all(promises);
    return all.some((val) => val);
  };
  const isSeparatorsCollapsed = async () => {
    let isCollapsedComponentByRolePromises: Promise<any>[] = [];
    const separatorsRole = ['seperator1', 'seperator2', 'seperator3'];
    isCollapsedComponentByRolePromises = separatorsRole.map((unitRole) =>
      isCollapsedComponentByRole(unitRole),
    );
    const values = await Promise.all(isCollapsedComponentByRolePromises);
    return values.every((element) => element === true);
  };
  const getCompToHide = async (componentRef) => {
    const type = await editorSDK.components.getType('token', { componentRef });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComp = async (componentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
  };

  const hideComp = async (componentRef, role) => {
    await editorSDK.components.refComponents.collapseReferredComponent(
      'token',
      {
        componentRef,
      },
    );
  };

  const addCompHandler = async ({ role }) => {
    const timerUnits = ['days', 'hours', 'minutes', 'seconds'];
    const widgetElements = ['text1', 'button1', 'text2', 'button2'];
    const componentRef = await getCollapsedRefComponentByRole(role);
    showComp(componentRef);
    const promises: any[] = [];
    if (timerUnits.includes(role)) {
      promises.push(
        new Promise((resolve) => resolve(updateUnitsProps(role, true))),
      );
      promises.push(
        new Promise((resolve) => resolve(addSeparatorInTimerUnits(role))),
      );
    }
    if (widgetElements.includes(role)) {
      promises.push(
        new Promise((resolve) => resolve(updateUnitsProps(role, true))),
      );
    }
    const promiseResults = await Promise.all(promises);
    return promiseResults[0];
  };

  const removeCompHandler = async (compRef, { role }) => {
    const timerUnits = [
      'days',
      'hours',
      'minutes',
      'seconds',
      'text1',
      'button1',
      'text2',
      'button2',
    ];
    const promises: Promise<any>[] = [];
    const compToHide = await getCompToHide(compRef);
    if (timerUnits.includes(role)) {
      promises.push(updateUnitsProps(role, false));
    }
    promises.push(hideComp(compToHide, role));
    const results = await Promise.all(promises);
    return results[1];
  };

  const updateUnitsProps = async (role, isRoleDisplayed) => {
    let newProps = {};
    switch (role) {
      case 'text1':
        newProps = {
          countdownTitleShowInElementsPanel_countdown: isRoleDisplayed,
        };
        break;
      case 'button1':
        newProps = {
          countdownButtonShowInElementsPanel_countdown: isRoleDisplayed,
        };
        break;
      case 'text2':
        newProps = {
          endMessageTitleShowInElementsPanel_countdown: isRoleDisplayed,
        };
        break;
      case 'button2':
        newProps = {
          endMessageButtonShowInElementsPanel_countdown: isRoleDisplayed,
        };
        break;
      case 'days':
        newProps = { daysShowInElementsPanel_countdown: isRoleDisplayed };
        break;
      case 'hours':
        newProps = { hoursShowInElementsPanel_countdown: isRoleDisplayed };
        break;
      case 'minutes':
        newProps = { minutesShowInElementsPanel_countdown: isRoleDisplayed };
        break;
      case 'seconds':
        newProps = { secondsShowInElementsPanel_countdown: isRoleDisplayed };
        break;
    }

    await editorSDK.application.appStudioWidgets.props.set('token', {
      widgetRef,
      newProps,
    });
    await editorSDK.application.livePreview.refresh('token', {
      shouldFetchData: false,
      source: 'AFTER_PROPS_CHANGE',
    });
  };

  const filteredCategoriesData =
    currentTab === 'Tab 1'
      ? categoriesData(t).filter((data) => {
          return data?.state !== END_MESSAGE_STATE;
        })
      : categoriesData(t).filter((data) => data?.state === END_MESSAGE_STATE);

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    categoriesData: filteredCategoriesData,
    elementsData: elementsData(t),
    addComponentHandler: addCompHandler,
    removeComponentHandler: removeCompHandler,
  });
};
